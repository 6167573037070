import React from 'react';
import ContentBlock from '../../../core/Components/ContentBlock';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';

const KeyPoints = ({ fields }) => (
  <div className="key-points">
    <ContentBlock fields={fields} />
  </div>
);

KeyPoints.propTypes = PropTypes.SitecoreItem.inject(PropTypes.ContentBlock);
KeyPoints.defaultProps = DefaultProps.SitecoreItem.inject(
  DefaultProps.ContentBlock,
);

export default KeyPoints;
